<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div id="services" class="py-12 bg-black">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="hServicesHeader lg:text-center">
        <p class="serviceHeader mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-200 sm:text-4xl">Services</p>
        <p class="serviceHeader mt-4 max-w-2xl text-xl text-gray-200 lg:mx-auto">Below are just some of the services we offer. Don't see what you need <a href="#contact" class="text-blue-400">Get in touch.</a></p>
      </div>

      <div class="serviceContainer mt-8 ">
        <div class="serviceCard">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
          <div v-for="feature in features" :key="feature.name" class="relative bg-black border-solid border-2 border-gray-600 rounded-xl py-8 bg-gradient-to-r from-black via-gray-800 to-black shadow-lg shadow-gray-700">
            <dt>
              <!-- <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
              </div> -->
              <p class="text-2xl leading-6 font-bold text-gray-100">{{ feature.name }}</p>
            </dt>
            <p class="leading-6 mt-2 font-medium text-blue-300">From £{{feature.price}}</p>
            <dd class="my-2 text-base mx-8 font-bold pt-2 text-gray-100">
              {{ feature.description }}
            </dd>
            <p class="text-blue-300 text-left text-xl ml-8 mb-2">Interior</p>
            <ol v-for="item in feature.interior" :key="item" class="text-gray-100">
              <li class="my-2"><span>-</span> {{ item }}</li>
            </ol>
            <p class="text-blue-300 text-left text-xl ml-8 py-4">Exterior</p>
            <ol v-for="item in feature.exterior" :key="item" class="text-gray-100">
              <li class="my-2"><span>-</span> {{ item }}</li>
            </ol>
          </div>
        </dl>
      </div>
      </div>
      <div class="relative mt-6 bg-black border-solid border-2 border-gray-600 rounded-xl py-8 bg-gradient-to-r from-black via-gray-800 to-black shadow-lg shadow-gray-700">
        <h2 class="text-2xl leading-6 font-bold text-gray-100">OTHER SERVICES</h2>
        <ul class="my-2 text-base mx-8 font-bold pt-2 text-gray-100">
          <li class="mb-2">Ceramic coatings 1 to 5 year coatings available</li>
          <li>Paint correction Stages 1,2 and 3</li>
        </ul>
        <p class="text-blue-200 italic text-sm">Please enquire for prices as these are dependant on vehicle and service required</p>
      </div>
      <!-- <div>
          <p class="mt-8 max-w-2xl text-xl text-gray-500 lg:mx-auto">Check out our Services page for a full breakdown of what we do or Get in touch.</p>
      </div> -->
    </div>
  </div>
</template>

<script setup>
// import { GlobeAltIcon, LightningBoltIcon, DesktopComputerIcon, DeviceMobileIcon } from '@heroicons/vue/outline'
import { ref, onMounted } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// const hServiceHeader = ref()



onMounted(() => {
  serviceScroll()
  servicesScroll1()
  
  console.log('monuted fired')
})

const features = [
  {
    name: 'MINI VALET',
    description:
      'The starter package:',
    price: '45',
    interior: ['Rubbish removed', 'Basic interior hoover (30 mins max)', 'Mats removed and hoovered', 'Dash & plastic wipe down'],
    exterior: ['Wheels faces and barrels cleaned using non acidic wheel cleaner', 'Wheels and tyres scrubbed & pressure washed', 'Traffic film remover or citrus pre wash applied', 'Snow foam pre wash with intricate areas cleaned using soft bristle brush', 'Hand wash using two bucket method and luxury microfibre mitts', 'glass cleaned', 'Vehicle dried using super soft drying towel',],
  },
  {
    name: 'FULL VALET',
    description:
      'Everything in Mini Valet plus:',
      interior: ['Full hoover including  sides of seats,parcel shelf, roof lining', 'Deeper clean on all plastics and door cards', 'Steering wheel cleaned', 'Interior glass cleaned', 'Pedals cleaned', 'Air freshener applied to carpets and mats',],
    exterior: ['All intricate areas cleaned using soft bristle brush', 'Iron fallout deposits treated on wheels and arches', 'Door sills and jambs cleaned', 'Glass cleaned', '3 month paint protection applied to paint and wheels', 'Tyres dressed'],
    price: '75',
  },
  {
    name: 'DEEP CLEAN',
    description:
      'Full valet plus:',
      interior: ['Full plastics and trims steam cleaned', 
'Pet hair removed', 
'Seats and carpets steam cleaned', 
'Seats and carpets shampooed and extracted if required', 
 ],
    exterior: ['Full chemical decontamination to paint surface and wheels', 'Tree sap removed','Tar removed', 'Clay bar treatment if required', 'Full 6 month sealant applied to all exterior areas (excluding under vehicle)', 'Full hand wax to vehicles paint', 'Glass cleaned using ceramic glass cleaner to assist water repellency​'],
      price: '175',
  },
  
]

const serviceScroll = () => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.hServicesHeader',
          start: 'top bottom',
          end: 'bottom top',
        }
      })
      .from('.serviceHeader', { y : innerWidth * 1, opacity: 0, duration: 1, ease: "power1.out"})
    }
    
    const servicesScroll1 = () => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.serviceContainer',
          start: 'top bottom',
          end: 'bottom top',
        }
      })
      .from('.serviceCard', { y : 500, opacity: 0, duration: 1.2 , ease: "power1.out"})
    }
</script>