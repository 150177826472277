<template>
  <h1 class="text-white font-bold text-2xl mb-4">OUR WORK</h1>
  <div class="md:gap-8 columns-3">
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/Logo.svg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
        <img
          src="../assets/img/double.jpg"
          alt=""
          class="rounded-2xl w-full h-52"
          />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/boot.jpg"
        alt=""
        class="rounded-2xl w-full h-60"
        />
    </div>
    <div class="py-3 sm:py-4">
        <img
          src="../assets/img/icecream.jpg"
          alt=""
          class="rounded-2xl w-full"
          />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/mercfoam.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/mercfront.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/range.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/side.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/steam.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/wiping.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    <div class="py-3 sm:py-4">
      <img
        src="../assets/img/exhaust.jpg"
        alt=""
        class="rounded-2xl w-full"
        />
    </div>
    
    
    
    
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>