<template>
  <div class="h-full">
  <h1 class="text-blue-500 text-3xl uppercase mt-4 font-bold">TERMS OF BUSINESS</h1>
  <h2 class="text-2xl text-blue-200 mt-2 mb-4 font-semibold">Please read carefully before booking</h2>

  <ul class="list-outside list-disc text-white marker:text-green font-semibold">
    <li class="mb-4 text-xl">- All bookings are subject to a 25% deposit payable to FMT Valeting via Bank transfer, If a deposit isn't received, your booking is not confirmed.</li>
    <li class="mb-4 text-xl">- Deposits are non refundable but are transferable if 48 hours notice is given of cancellations.</li>
    <li class="mb-4 text-xl">- Payment is due immediately after handover.</li>
    <li class="mb-4 text-xl">- Every effort must be made to remove personal items from the vehicle prior to work commencing.</li>
    <li class="mb-4 text-xl">- Our prices are a guideline, we reserve the right to charge extra for unreasonable levels of soiling, pet hair, vomit, bodily matter or anything else that demands extra time to rectify.</li>
    <li class="mb-4 text-xl">- In the event of adverse weather, your deposit will be held until a rebook can be arrange.</li>
    <li class="mb-4 text-xl">- We will require suitable parking for our van and sufficient space to fully open all doors on your vehicle independently.</li>
    <li class="mb-4 text-xl">- We require an electricity supply at your chose location to carry out any work. We do carry water on board however in some situations we may need access to extra water.</li>
    <li class="mb-4 text-xl">- Travel is free for the first 30 minutes, then chargeable at £20ph both ways.</li>
    

  </ul>

  <Footer/>
</div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    
    Footer
},

}



</script>