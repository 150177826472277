<template>
<div class="home">
<div class="hero w-full bg-center bg-cover h-[32rem]">
            <div class="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              
                <div class="grid md:grid-cols-2 gap-4">
                  <div class="logoContainer">
                    <img src="../assets/img/Logo.svg" class="opacity-70 rounded-xl logo object-cover h-64 w-full" alt="">
                  </div>
                  <div class="flex flex-col justify-end">
                  <transition
                  appear
                  @before-enter="beforeEnter"
                  @enter="enter"
                  >
                    <h1 class="text-2xl font-semibold text-white uppercase lg:text-3xl">Premium <span class="text-blue-400">valeting and detailing</span> covering oxfordshire</h1>
                  </transition>
                  <transition
                  appear
                  @before-enter="beforeEnter"
                  @enter="enter"
                  >
                    <p class="text-gray-100">The highest quality valeting and detailing coming to you, whether that's at home or work</p>
                  </transition>
                  <transition
                  appear
                  @before-enter="beforeEnter"
                  @enter="enter"
                  >
                    <div class="mt-4" >
                    <a href="#contact" class="w-full px-4 py-2 mt-4 text-sm font-medium text-white uppercase transition-colors duration-200 transform bg-blue-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">Get In Touch</a>
                  </div>
                </transition>
              </div>
                </div>
            </div>
        </div>
  </div>

  <About />
  <Services />
  <PreviousWork />
  <Contact/>
  <Footer/>
  
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue'
import PreviousWork from '@/components/PreviousWork.vue'
import Services from '@/components/Services.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'

import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);



export default {
  name: 'HomeView',
  components: {
    About,
    PreviousWork,
    Services,
    Contact,
    Footer
},
setup() {
    const beforeEnter = (el) => {
        
        el.style.transform = 'translateY(-300px)'
        el.style.opacity = 0
    } 
    const enter = (el) => {
        
        gsap.to(el, {
            duration: 1,
            y: 0,
            opacity: 1,
        })
    }
    return { beforeEnter, enter}
    }
}

</script>
<style scoped>
.hero {
  background-image: url('~@/assets/img/double.jpg');
}

.fade-enter-from {
    opacity: 0;
    transform: translateX(100px);
}
.fade-enter-active {
    transition: all 1s ease;
}
.fade-leave-to {
    opacity: 0;
}
.fade-leave-active {
    transition: opacity 3s ease;
}

/* .logoContainer{
  width: 80%;
  height: 80%;
  padding: 20px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

</style>
