<template>
  <div>
    <div class="bg-black bg-gradient-to-r from-black via-gray-800 to-black">
      <nav
        class="
          container
          <!-- px-6
          py-8 -->
          mx-auto
          md:flex md:justify-between md:items-center
        "
      >
        <div class="flex items-center justify-between">
          <router-link
            to="/"
            class="
              <!-- md:text-xl
              font-bold
              text-gray-100
              md:text-2xl
              hover:text-gray-300 -->
            "
            >
            <!-- <p class="logotext text-3xl">F M T</p> 
            <p class="text-sm font-thin">VALETING &#38; DETAILING</p>
             -->
          </router-link>
          <!-- <div class="text-center">
                  <div class="flex justify-center">
                    <embed src="../assets/img/Logo.svg" alt="" class="logoSvg  opacity-70">
                  </div>
                  </div> -->
          <!-- Mobile menu button -->
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button
              type="button"
              class="
                text-gray-100
                hover:text-gray-400
                focus:outline-none focus:text-gray-400
              "
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>


        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
          :class="showMenu ? 'flex' : 'hidden'"
          class="
            flex-col
            mt-8
            space-y-4
            md:flex
            md:space-y-0
            md:flex-row
            md:items-center
            md:space-x-10
            md:mt-0
          "
        >
          <!-- <a href="#top" class="font-bold text-gray-200 hover:text-blue-400">
            Home
          </a> -->
          <a href="/" class="font-bold text-gray-100 hover:text-blue-400">
            Home
          </a>
          <a href="/#about" class="font-bold text-gray-100 hover:text-blue-400">
            About
          </a>
          <a href="/#services" class="font-bold text-gray-100 hover:text-blue-400">
            Services
          </a>
          <a href="/#contact" class="font-bold text-gray-100 hover:text-blue-400">
            Contact Us
          </a>
          <a href="/tob" class="font-bold text-gray-100 hover:text-blue-400">
            Terms Of Business
          </a>
        </ul>
      </nav>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style>
  .logotext{
    font-family: 'Montagu Slab', serif;
    font-size: xx-large;
  }
  .logo{
    background-image: url('~@/assets/img/Logo.svg');
  }
</style>